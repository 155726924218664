<script setup>
import { computed, onMounted, reactive, watch } from 'vue'
import {mapGetters, useStore} from 'vuex'
import { internalZipLookup, ipqsCheckIfPhoneIsValid } from '@/js/utils'

const stepName = 'DebtReliefQuestionPhoneAndZip'
const store = useStore()
const props = defineProps({
  progress: {
    type: Number,
    required: true
  }
})

const state = reactive({
  buttonValue: 'Submit',
  phoneValue: '',
  zipValue: '',
  city: '',
  state: '',
  county: '',
  phoneError: computed(() => store.state.phoneError),
  zipError: false,
  showModal: computed(() => store.state.twilioSmsVerify.toggleModalWithSmsVerification),
  phoneCodeIsApproved: computed(() => store.state.twilioSmsVerify.approved),
  phoneErrorMsg: 'Please enter your phone number.'
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

watch(() => state.phoneValue,() => {
  if (state.phoneValue.length > 0) {
    store.commit('twilioSmsVerify/RESET_TWILIO')
    store.commit('SET_PHONE_ERROR', false)
  }
})

watch(() => state.phoneCodeIsApproved, () => {
  if (!state.phoneError &&
      !state.zipError &&
      state.phoneCodeIsApproved) {
    setAnswer()
  }
})


async function phoneValidation() {
  if (!state.phoneValue || state.phoneValue.length < 14) {
    store.commit('SET_PHONE_ERROR', true)
    state.phoneErrorMsg = 'Please enter your phone number.'
    return
  }

  const { valid, fullValid } = await ipqsCheckIfPhoneIsValid(state.phoneValue);
  if (!fullValid) {
    store.commit('SET_PHONE_ERROR', true)
    state.phoneErrorMsg = 'Please enter a valid phone number.'
  }

  if (window.location.pathname.includes('/v2') && !state.phoneCodeIsApproved && valid) {
    showTwilioPhoneModal()
  }
}

async function zipValidation() {
  if (!state.zipValue || state.zipValue.length < 5) {
    state.zipError = true
    return
  }

  const zipData = await internalZipLookup(state.zipValue)
  state.zipError = !zipData.zip;

  if (!state.zipError) {
    state.city = zipData.city
    state.state = zipData.state
    state.county = zipData.county
  }
}

const showTwilioPhoneModal = () => {
  store.commit('SET_PHONE_ERROR', true)
  state.phoneErrorMsg = 'Please verify your phone number.'

  document.body.classList.add('modal-open')
  store.commit('twilioSmsVerify/TOGGLE_MODAL_WITH_SMS_VERIFICATION')

  if (state.showModal) {
    store.dispatch('twilioSmsVerify/postPhone', state.phoneValue)
  }
}

async function checkAllValidations() {
  await Promise.all([
    phoneValidation(),
    zipValidation(),
  ])

  return !state.phoneError && !state.zipError;
}

const setAnswer = async (answer) => {
  state.buttonValue = 'Loading...'
  const checkForErrors = await checkAllValidations()

  if (!checkForErrors) {
    state.buttonValue = 'Submit'
    return
  }

  store.commit('SET_ARRAY_OF_FIELDS', [
    { field: 'phone', value: state.phoneValue.replace(/[()\-\s]+/g, '') },
    { field: 'zip_code', value: state.zipValue },
    { field: 'city', value: state.city },
    { field: 'state', value: state.state },
    { field: 'county', value: state.county },
    { field: 'case_description', value: '' },
    { field: 'case_description_filled', value: 'no' },
    { field: 'is_gibberish', value: 'no' },
    { field: 'has_bad_words', value: 'no' },
    { field: 'enough_words', value: 'yes' },
    { field: 'monthly_income', value: '' },
    { field: 'own_real_estate', value: '' },
    { field: 'value_of_assets', value: '' },
  ])

  if (window.location.pathname === '/v4') {
    await store.dispatch('debtReliefFlowV4/goToNextStep', stepName)
    return
  }

  await store.dispatch('debtReliefFlowV3/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="progress-bar">
      <div class="progress-bar-fill" :style="{ width: props.progress + '%'}"></div>
    </div>
    <h3 class="question">Last Step</h3>
    <div class="answers">
      <input
          v-model="state.phoneValue"
          :class="{'alert-border': state.phoneError}"
          :disabled="state.buttonText === 'LOADING...'"
          v-maska="'(###) ###-####'"
          class="input-style"
          name="phone"
          type="tel"
          placeholder="Phone"
      />
      <p
          :style="[state.phoneError ? {'opacity': '1'} : {'opacity': '0'}]"
          class="error-msg"
      >
        {{ state.phoneErrorMsg }}
      </p>
      <input
          v-model="state.zipValue"
          :class="{'alert-border': state.zipError}"
          :disabled="state.buttonText === 'LOADING...'"
          v-maska="'#####'"
          class="input-style"
          name="zip"
          type="tel"
          placeholder="Zip"
      />
      <p
          :style="[state.zipError ? {'opacity': '1'} : {'opacity': '0'}]"
          class="error-msg"
      >
        Please enter your zip code.
      </p>
      <button
          :disabled="state.buttonValue === 'Loading...'"
          @click="setAnswer"
          :class="{'disabled': state.buttonValue === 'Loading...'}"
          class="submit-button"
      >
        {{ state.buttonValue }}
      </button>
      <label for="">
        <input type="hidden" id="leadid_tcpa_disclosure"/>
        <p class="toa">
          By clicking the "Submit" button, you agree to
          the <a onclick="window.open('/terms-and-conditions','','scrollbars=yes,width=600,height=400')">Terms & Conditions</a> and
          <a onclick="window.open('/privacy-policy','','scrollbars=yes,width=600,height=400')">Privacy Policy</a> and authorize Debt
          Reduction Team and its <a onclick="window.open('/partners','','scrollbars=yes,width=780,height=650')">partners</a> to contact you
          by email or at the phone number you entered using automated
          technology including recurring auto-dialers, pre-recorded
          messages, and text messages, even if your phone is a mobile
          number or is currently listed on any state, federal, or
          corporate "Do Not Call" list, and you are not required to give
          your consent as a condition of service. You understand that
          your telephone company may impose charges on you for these
          contacts, and that you can revoke this consent at any time.
          For SMS campaigns Text STOP to cancel and HELP for help.
          Message and data rates may apply. Consent is not required as a
          condition to utilize debtreductionteam.com services and you are under no
          obligation to purchase anything.
        </p>
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin: auto;
  background-color: #f5f6f6;
  border-radius: 5px;
  padding: 30px 20px 20px 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  max-width: 450px;
  width: 100%;
  .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #e6e6e6;
    position: absolute;
    top: 0;
    left: 0;
    .progress-bar-fill {
      height: 100%;
      background-color: #72ccbf;
    }
  }
  .question {
    font-family: "librefranklin-extrabold", sans-serif;
    padding: 0;
    font-size: 30px;
    line-height: 36px;
    color: #72ccbf;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 1px 1px #256483;
  }
  .answers {
    display: flex;
    flex-direction: column;
    align-items: center;
    .input-style {
      float: left;
      font-size: 18px;
      line-height: 30px;
      color: #666666;
      font-weight: normal;
      font-family: "Libre Franklin", sans-serif;
      text-transform: none;
      background-color: #fff;
      border: 1px solid #cccccc;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      padding: 9px 15px;
      text-overflow: ellipsis;
      max-width: 300px;
      width: 100%;
      box-sizing: border-box;
      height: 50px;
      &.select-style {
        height: 50px;
        color: #666666;
      }
      &:focus {
        outline: none;
      }
      &.alert-border {
        border: 1px #ff0000 solid;
        animation: blink 1s;
        animation-iteration-count: 3;
        background-color: #f8cdcd;
      }
    }
    .error-msg {
      font-family: "Libre Franklin", sans-serif;
      font-size: 12px;
      color: red;
      margin: 2px 0 5px 10px;
      opacity: 0;
      min-height: 14px;
      max-width: 300px;
      width: 100%;
    }
    .submit-button {
      border: none;
      margin: 5px auto 20px auto;
      font-family: "Libre Franklin", sans-serif;
      background-color: #03adb7;
      color: #f5f6f6;
      font-size: 20px;
      border-radius: 8px;
      max-width: 150px;
      width: 100%;
      padding: 8px;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #169eaa;
      }
      &.disabled {
        background-color: #4a858a;
        cursor: not-allowed;
      }
    }
    .toa {
      font-size: 12pt;
      line-height: 14px;
      color: black;
      font-family: "Libre Franklin", sans-serif;
      text-align: justify;
      a {
        color: #1eabb8;
        cursor: pointer;
      }
    }
  }
}
</style>