<script setup>
import { onMounted, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import {EMAIL_REGEX, hasBadWords} from '@/js/constants'
import {ipqsIsEmailInvalid, isNameGibberish} from '@/js/utils'

const stepName = 'DebtReliefQuestionNameAndEmail'
const store = useStore()
const props = defineProps({
  progress: {
    type: Number,
    required: true
  }
})

const state = reactive({
  buttonValue: 'Submit',
  firstNameValue: '',
  firstNameError: false,
  lastNameValue: '',
  lastNameError: false,
  emailValue: '',
  emailError: false,
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

watch(() => [state.firstNameValue], () => {
  if (state.firstNameValue.length > 0) state.firstNameError = false
})

watch(() => [state.lastNameValue], () => {
  if (state.lastNameValue.length > 0) state.lastNameError = false
})

watch(() => [state.emailValue], () => {
  if (state.emailValue.length > 0) state.emailError = false
})

async function inputValidation() {
  state.firstNameError = state.firstNameValue.length === 0
  state.lastNameError = state.lastNameValue.length === 0
  state.emailError = state.emailValue.length === 0

  if (state.emailError === false) await emailValidation()
}

async function emailValidation() {
  const validFormat = EMAIL_REGEX.test(String(state.emailValue).toLowerCase())

  if (state.emailValue.length === 0 || state.emailError) {
    state.emailError = true
    return
  }

  if (validFormat) {
    state.emailError = await ipqsIsEmailInvalid(state.emailValue)
  } else {
    state.emailError = true
  }

  return state.emailError
}

const setAnswer = async (answer) => {
  state.buttonValue = 'Loading...'
  await inputValidation()

  if (state.firstNameError || state.lastNameError || state.emailError) {
    state.buttonValue = 'Submit'
    return
  }

  let nameGibberish = isNameGibberish(state.firstNameValue) ? 'yes' : 'no'
  if (nameGibberish === 'no') {
    nameGibberish = isNameGibberish(state.lastNameValue) ? 'yes' : 'no'
  }

  //test if name has bad words
  let nameBadWord = hasBadWords().test(state.firstNameValue) ? 'yes' : 'no'
  if (nameBadWord === 'no') {
    nameBadWord = hasBadWords().test(state.lastNameValue) ? 'yes' : 'no'
  }

  store.commit('SET_ARRAY_OF_FIELDS', [
    { field: 'first_name', value: state.firstNameValue },
    { field: 'last_name', value: state.lastNameValue },
    { field: 'email', value: state.emailValue },
    { field: 'name_gibberish', value: nameGibberish },
    { field: 'name_bad_word', value: nameBadWord }
  ])

  if (window.location.pathname === '/v4') {
    await store.dispatch('debtReliefFlowV4/goToNextStep', stepName)
    return
  }

  await store.dispatch('debtReliefFlowV3/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="progress-bar">
      <div class="progress-bar-fill" :style="{ width: props.progress + '%' }"></div>
    </div>
    <h3 class="question">Complete the form below to check if you qualify for debt relief?</h3>
    <div class="answers">
      <input
          :disabled="state.buttonValue === 'Loading...'"
          v-model="state.firstNameValue"
          v-maska="{ mask: 'S*', tokens: { 'S': { pattern: /[a-zA-Z().\-'\s`]/ }}}"
          @keyup.enter="setAnswer"
          :class="{'alert-border': state.firstNameError}"
          class="input-style"
          type="text"
          placeholder="First Name"
      />
      <p
          :style="[state.firstNameError ? {'opacity': '1'} : {'opacity': '0'}]"
          class="error-msg"
      >
        Please enter your first name.
      </p>
      <input
          :disabled="state.buttonValue === 'Loading...'"
          v-model="state.lastNameValue"
          v-maska="{ mask: 'S*', tokens: { 'S': { pattern: /[a-zA-Z().\-'\s`]/ }}}"
          @keyup.enter="setAnswer"
          :class="{'alert-border': state.lastNameError}"
          class="input-style"
          type="text"
          placeholder="Last Name"
      />
      <p
          :style="[state.lastNameError ? {'opacity': '1'} : {'opacity': '0'}]"
          class="error-msg"
      >
        Please enter your last name.
      </p>
      <input
          :disabled="state.buttonValue === 'Loading...'"
          v-model="state.emailValue"
          @keyup.enter="setAnswer"
          :class="{'alert-border': state.emailError}"
          class="input-style"
          placeholder="Email Address"
      />
      <p
          :style="[state.emailError ? {'opacity': '1'} : {'opacity': '0'}]"
          class="error-msg"
      >
        Please enter your email address.
      </p>
      <button
          :disabled="state.buttonValue === 'Loading...'"
          @click="setAnswer"
          :class="{'disabled': state.buttonValue === 'Loading...'}"
          class="submit-button"
      >
        {{ state.buttonValue }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin: auto;
  background-color: #f5f6f6;
  border-radius: 5px;
  padding: 30px 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  max-width: 450px;
  width: 100%;
  .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #e6e6e6;
    position: absolute;
    top: 0;
    left: 0;
    .progress-bar-fill {
      height: 100%;
      background-color: #72ccbf;
    }
  }
  .question {
    font-family: "librefranklin-extrabold", sans-serif;
    padding: 0;
    font-size: 30px;
    line-height: 36px;
    color: #72ccbf;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 1px 1px #256483;
  }
  .answers {
    display: flex;
    flex-direction: column;
    align-items: center;
    .input-style {
      float: left;
      font-size: 18px;
      line-height: 30px;
      color: #666666;
      font-weight: normal;
      font-family: "Libre Franklin", sans-serif;
      text-transform: none;
      background-color: #fff;
      border: 1px solid #cccccc;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      padding: 9px 15px;
      text-overflow: ellipsis;
      max-width: 300px;
      width: 100%;
      box-sizing: border-box;
      height: 50px;
      &.select-style {
        height: 50px;
        color: #666666;
      }
      &:focus {
        outline: none;
      }
      &.alert-border {
        border: 1px #ff0000 solid;
        animation: blink 1s;
        animation-iteration-count: 3;
        background-color: #f8cdcd;
      }
    }
    .error-msg {
      font-family: "Libre Franklin", sans-serif;
      font-size: 12px;
      color: red;
      margin: 2px 0 5px 10px;
      opacity: 0;
      min-height: 14px;
      max-width: 300px;
      width: 100%;
    }
    .submit-button {
      border: none;
      margin: 5px auto 0 auto;
      font-family: "Libre Franklin", sans-serif;
      background-color: #03adb7;
      color: #f5f6f6;
      font-size: 20px;
      border-radius: 8px;
      max-width: 150px;
      width: 100%;
      padding: 8px;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #169eaa;
      }
      &.disabled {
        background-color: #4a858a;
        cursor: not-allowed;
      }
    }
  }
}
</style>