<template>
  <div class="business-icons-box">
    <img
      src="../assets/images/secure-icon-01.jpeg"
      alt="First business icons"
    />
    <img
      src="../assets/images/secure-icon-02.jpeg"
      alt="Second business icons"
    />
  </div>
</template>

<style scoped lang="scss">
.business-icons-box {
  max-width: 1200px;
  display: flex;
  justify-content: center;
  padding: 60px;
  margin: auto;
  img {
    max-width: 500px;
    max-height: 108px;
  }
}

@media (max-width: 900px) {
  .business-icons-box {
    display: block;
    text-align: center;
    padding: 20px;
    img:last-child {
      margin-right: 50px;
    }
  }
}

@media (max-width: 550px) {
  .business-icons-box {
    img {
      &:first-child {
        width: 100%;
      }
      &:last-child {
        width: calc(100% - 100px);
      }
    }
  }
}
</style>
