<script setup>
import { useStore } from 'vuex'
import { reactive, computed, onBeforeMount } from 'vue'
import { FLOW_STEPS } from '@/js/steps-for-flow'
import NavBar from '@/components/NavBar.vue'
import BusinessIconsV3 from '@/components/BusinessIconsV3.vue'
import TheFooterV3 from '@/components/TheFooterV3.vue'

const store = useStore();
const currentStep = computed(() => store.state.currentStep)
const progress = computed(() => store.state.progress)
const state = reactive({
  minHeight: computed(() => store.state.minHeightOfTheLastComponent)
})

onBeforeMount(() => {
  store.dispatch('init')
  store.dispatch('debtReliefFlowV3/goToNextStep', 'StartPath')
})
</script>

<template>
  <NavBar/>
  <div class="main-container">
    <div class="overlay"></div>
    <div class="question-container" :style="{'min-height': state.minHeight + 'px'}">
      <transition name="fade" mode="out-in">
        <component
            :is="FLOW_STEPS[currentStep].component"
            :progress="progress"
        />
      </transition>
    </div>
  </div>
  <BusinessIconsV3 />
  <TheFooterV3 />
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.main-container {
  flex-grow: 1;
  background: url("../assets/images/young-people.jpeg") top no-repeat #d9dddf;
  background-size: cover;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.37);
    height: 100%;
    width: 100%;
  }
}
</style>