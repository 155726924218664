<script setup>
import {onMounted, reactive} from 'vue'
import { useStore } from 'vuex'

const ANSWERS = {
  '$0-$5,000': '$0 - $5,000',
  '$5,000 - $9,999': '$5,000 - $9,999',
  '$10,000 - $14,999': '$10,000 - $14,999',
  '$15,000 - $19,999': '$15,000 - $19,999',
  '$20,000 - $29,999': '$20,000 - $29,999',
  '$30,000 - $39,999': '$30,000 - $39,999',
  '$40,000 - $49,999': '$40,000 - $49,999',
  '$50,000 - $59,999': '$50,000 - $59,999',
  '$60,000 - $69,999': '$60,000 - $69,999',
  '$70,000 - $79,999': '$70,000 - $79,999',
  '$80,000 - $89,999': '$80,000 - $89,999',
  '$90,000 - $99,999': '$90,000 - $99,999',
  '$100,000 +': '$100,000+'
}
const stepName = 'DebtReliefQuestionSelectDebtAmount'
const store = useStore()
const props = defineProps({
  progress: {
    type: Number,
    required: true
  }
})

const state = reactive({
  buttonValue: 'Submit',
  debtAmount: '',
  redBorder: false
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

const setAnswer = () => {
  if (state.debtAmount === '') {
    state.redBorder = true
    return
  }
  state.buttonValue = 'Loading...'
  store.commit('SET_FIELD', { field: 'debt_amount', value: state.debtAmount })
  store.dispatch('debtReliefFlowV4/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="progress-bar">
      <div class="progress-bar-fill" :style="{ width: props.progress + '%'}"></div>
    </div>
    <h3 class="question">What debt amount you have?</h3>
    <div class="answers">
      <select
          v-model="state.debtAmount"
          @change="state.redBorder = false"
          :class="{'alert-border': state.redBorder}"
          :disabled="state.buttonValue === 'Loading...'"
          class="select"
      >
        <option value="" disabled>Select debt type</option>
        <option
            v-for="(value, key) in ANSWERS"
            :value="key"
        >
          {{ value }}
        </option>
      </select>
      <button
          :disabled="state.buttonValue === 'Loading...'"
          @click="setAnswer"
          :class="{'disabled': state.buttonValue === 'Loading...'}"
          class="submit-button"
      >
        {{ state.buttonValue }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin: auto;
  background-color: #f5f6f6;
  border-radius: 5px;
  padding: 30px 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  max-width: 450px;
  width: 100%;
  .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #e6e6e6;
    position: absolute;
    top: 0;
    left: 0;
    .progress-bar-fill {
      height: 100%;
      background-color: #72ccbf;
    }
  }
  .question {
    font-family: "librefranklin-extrabold", sans-serif;
    padding: 0;
    font-size: 30px;
    line-height: 36px;
    color: #72ccbf;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 1px 1px #256483;
  }
  .answers {
    display: flex;
    flex-direction: column;
    align-items: center;
    .select {
      font-size: 18px;
      line-height: 30px;
      color: #666666;
      font-weight: normal;
      font-family: "Libre Franklin", sans-serif;
      text-transform: none;
      background-color: #fff;
      border: 1px solid #cccccc;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      border-radius: 5px;
      padding: 9px 15px;
      text-overflow: ellipsis;
      max-width: 300px;
      width: 100%;
      box-sizing: border-box;
      height: 50px;
      &.alert-border {
        border: 1px #ff0000 solid;
        animation: blink 1s;
        animation-iteration-count: 3;
        background-color: #f8cdcd;
      }
    }
    .submit-button {
      border: none;
      margin: 25px auto 0 auto;
      font-family: "Libre Franklin", sans-serif;
      background-color: #03adb7;
      color: #f5f6f6;
      font-size: 20px;
      border-radius: 8px;
      max-width: 150px;
      width: 100%;
      padding: 8px;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #169eaa;
      }
      &.disabled {
        background-color: #4a858a;
        cursor: not-allowed;
      }
    }
  }
}
</style>