import DebtReliefQuestionDebtAmount from '@/components/debt-relief-flow/DebtReliefQuestionDebtAmount.vue'
import DebtReliefQuestionDebtType from '@/components/debt-relief-flow/DebtReliefQuestionDebtType.vue'
import DebtReliefQuestionNameAndEmail from '@/components/debt-relief-flow/DebtReliefQuestionNameAndEmail.vue'
import DebtReliefQuestionPhoneAndZip from '@/components/debt-relief-flow/DebtReliefQuestionPhoneAndZip.vue'
import DebtReliefQuestionSelectDebtType from '@/components/debt-relief-flow/DebtReliefQuestionSelectDebtType.vue'
import DebtReliefQuestionSelectDebtAmount from '@/components/debt-relief-flow/DebtReliefQuestionSelectDebtAmount.vue'

export const FLOW_STEPS = {
    DebtReliefQuestionDebtType: {
        component: DebtReliefQuestionDebtType
    },
    DebtReliefQuestionDebtAmount: {
        component: DebtReliefQuestionDebtAmount
    },
    DebtReliefQuestionNameAndEmail: {
        component: DebtReliefQuestionNameAndEmail
    },
    DebtReliefQuestionPhoneAndZip: {
        component: DebtReliefQuestionPhoneAndZip
    },
    DebtReliefQuestionSelectDebtType: {
        component: DebtReliefQuestionSelectDebtType
    },
    DebtReliefQuestionSelectDebtAmount: {
        component: DebtReliefQuestionSelectDebtAmount
    }
}