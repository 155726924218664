<script setup>
import { onMounted } from 'vue'
import { useStore } from 'vuex'

const ANSWERS = [
  'Credit card',
  'Tax debt',
  'Personal loan'
]
const stepName = 'DebtReliefQuestionDebtType'
const store = useStore()
const props = defineProps({
  progress: {
    type: Number,
    required: true
  }
})

onMounted(() => {
  store.commit('SET_IS_LOADING', false, { root: true })
  store.commit('SET_MIN_HEIGHT_OF_THE_LAST_COMPONENT', document.getElementById('component-height').clientHeight, { root: true })
})

const setAnswer = (answer) => {
  store.commit('SET_FIELD', { field: 'debt_type', value: answer })
  store.dispatch('debtReliefFlowV3/goToNextStep', stepName)
}
</script>

<template>
  <div id="component-height" class="container">
    <div class="progress-bar">
      <div class="progress-bar-fill" :style="{ width: props.progress + '%'}"></div>
    </div>
    <h3 class="question">What debt type you have?</h3>
    <div class="answers">
      <div
          v-for="answer in ANSWERS"
          @click="setAnswer(answer)"
          class="answer"
      >
       {{ answer }}
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  margin: auto;
  background-color: #f5f6f6;
  border-radius: 5px;
  padding: 30px 20px;
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  max-width: 450px;
  width: 100%;
  .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #e6e6e6;
    position: absolute;
    top: 0;
    left: 0;
    .progress-bar-fill {
      height: 100%;
      background-color: #72ccbf;
    }
  }
  .question {
    font-family: "librefranklin-extrabold", sans-serif;
    padding: 0;
    font-size: 30px;
    line-height: 36px;
    color: #72ccbf;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 1px 1px #256483;
  }
  .answers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 12px;
    .answer {
      font-family: "Libre Franklin", sans-serif;
      background-color: #03adb7;
      color: #f5f6f6;
      font-size: 20px;
      border-radius: 8px;
      max-width: 300px;
      width: 100%;
      padding: 9px 15px;
      text-align: center;
      cursor: pointer;
      box-sizing: border-box;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: #169eaa;
      }
    }
  }
}
</style>